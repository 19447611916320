<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">{{title}}</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md3>
            <v-select
              v-if="!saving"
              v-model="year_of"
              class="mx-2"
              :items="year_of_items"
              item-value="year"
              item-text="year"
              label="Year Of"
              required
              :rules="rules.combobox_rule"
              dense
              @change="selected_year"
              outlined
              v-show="is_authorize_all"
            ></v-select>
            <v-select
              v-if="!saving"
              v-model="month_of_id"
              class="mx-2"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              required
              :rules="rules.combobox_rule"
              dense
              outlined
              @change="selected_month"
              v-show="is_authorize_all"
            ></v-select>
            <v-select
              v-if="!saving"
              outlined
              class="mx-2"
              v-model="category_id"
              dense
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
              v-show="is_authorize_all"
            ></v-select>
            <v-select
              outlined
              v-if="!is_forbidden && !saving"
              class="mx-2"
              v-model="selection"
              dense
              label="Selection"
              :items="['Branch','Regional','Territory']"
              :rules="rules.combobox_rule"
              @change="selected_category"
              v-show="is_authorize_all"
            ></v-select>
            <v-select
              v-if="!saving"
              outlined
              class="mx-2"
              v-model="branch_id"
              dense
              label="Department"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              :rules="rules.combobox_rule"
              @change="selected_month"
              v-show="is_authorize_all"
            ></v-select>
            <v-select
              v-if="!saving"
              outlined
              class="mx-2"
              v-model="type_of_fund"
              dense
              label="Type of Fund"
              :items="['Revolving Fund','Sales Drive']"
              :rules="rules.combobox_rule"
              @change="selected_month"
              v-show="is_authorize_all"
            ></v-select>
            <v-progress-circular
              v-if="saving"
              :size=50
              :width="5"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-flex>
          <v-flex xs12 md12>
            <h2>Print |
              <v-icon
                class="mr-2"
                color="success"
                @click="print_data"
                v-if="!printt"
              >
                {{icons.mdiPrinter}}
              </v-icon>
              <v-progress-circular
                :size=25
                :width="5"
                color="success"
                indeterminate
                v-else
              ></v-progress-circular>
              Total Fund: P {{ total_amount1 }} |
              Total Balance: P {{ total_amount }}
            </h2>
            <v-data-table
              dense
              :headers="headers"
              :items="data_items"
              height="600"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.description }}
                  </td>
                  <td>
                    {{ item.payment_for }}
                  </td>
                  <td>
                    {{ item.credit_to }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount) }}
                  </td>
                  <td>
                    {{ formatPrice(item.balance) }}
                  </td>
                  <td>
                    <v-icon
                      v-if="!is_viewing"
                      class="mr-2"
                      color="info"
                      @click="view_liquidations(item)"
                    >
                      {{icons.mdiFileFind }}
                    </v-icon>
                    <v-progress-circular color="info" indeterminate
                                         v-else></v-progress-circular>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card>
    <v-dialog :key="this.key" v-model="is_view_liquidation" persistent>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title
          ><h5 class="font-weight-light">LIQUIDATIONS DATA</h5>
          </v-toolbar-title>
        </v-toolbar>
        <h2 class="mr-2 mx-2 mt-3">Print |
          <v-icon
            color="success"
            @click="print_data2"
            v-if="!printt"
          >
            {{icons.mdiPrinter}}
          </v-icon>
          <v-progress-circular
            :size=25
            :width="5"
            color="success"
            indeterminate
            v-else
          ></v-progress-circular>
        </h2>
        <v-row dense class="mt-3">
          <v-col md="12" cols="12">
            <v-data-table
              dense
              :headers="headers2"
              :items="liquidated_items"
              height="600"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.cash_voucher.voucher_no
                    }}
                  </td>
                  <td>
                    {{ item.category.category
                    }}
                  </td>
                  <td>
                    {{ item.branch.branch_code
                    }}
                  </td>
                  <td>
                    {{ item.credit_to
                    }}
                  </td>
                  <td>
                    {{ item.particulars.particulars
                    }}
                  </td>
                  <td>
                    {{ item.description
                    }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount)
                    }}
                  </td>
                  <td>
                    {{ item.or_no
                    }}
                  </td>
                  <td>
                    {{ item.specify
                    }}
                  </td>
                  <td class="text-center">
                    <v-icon
                      v-if="!is_deleting"
                      class="mr-2"
                      color="info"
                      @click="view_receipt(item.id)"
                    >
                      {{icons.mdiEyeCircle }}
                    </v-icon>
                    <v-progress-circular color="info" indeterminate
                                         v-else></v-progress-circular>
                  </td>
                  <td class="text-center" v-if="is_authorize_all">
                    <v-icon
                      v-if="!is_deleting"
                      class="mr-2"
                      color="error"
                      @click="delete_data(item.id)"
                    >
                      {{icons.mdiDelete }}
                    </v-icon>
                    <v-progress-circular color="info" indeterminate
                                         v-else></v-progress-circular>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <v-btn color="error" @click="close_dialog"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="can_view_uploaded_cheque" persistent max-width="80%">
      <ViewUploadCheque
        :key="this.key2"
        :title="'VIEW UPLOADED RECEIPT'"
        :data_items="this.uploaded_data_items"
      ></ViewUploadCheque>
      <v-btn color="error" @click="can_view_uploaded_cheque = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose, mdiSwapHorizontal
    , mdiPrinter
    , mdiDelete
    , mdiFileFind
    , mdiEyeCircle
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import ViewUploadCheque from '@/views/user_interface/memos/ViewUploadMemos'

  const initialState = () => {
    return {
      saving: false,
      is_viewing: false,
      is_deleting: false,
      is_view_liquidation: false,
      printt: false,
      can_view_uploaded_cheque: false,
      uploaded_data_items: [],

      is_forbidden: false,
      alert: false,
      saving_data: false,
      alert_message: '',
      key: 0,
      key2: 0,
      headers: [
        {text: 'ID', value: 'no', sortable: false},
        {text: 'Description', value: 'category.category', sortable: false},
        {text: 'Payment For', value: 'branch.branch_code', sortable: false},
        {text: 'Credit To', value: 'equipment', sortable: false},
        {text: 'Amount', value: 'transmital_no', sortable: false},
        {text: 'Balance', value: 'equipment_serial_no', sortable: false},
        {text: 'View', value: 'equipment_serial_no', sortable: false},
      ],
      headers2: [
        {text: 'Voucher #', value: 'date_of_deposit', sortable: false},
        {text: 'Category', value: 'date_of_deposit', sortable: false},
        {text: 'Branch', value: 'date_of_deposit', sortable: false},
        {text: 'Credited To', value: 'date_of_deposit', sortable: false},
        {text: 'Particulars', value: 'date_of_deposit', sortable: false},
        {text: 'Description', value: 'date_of_deposit', sortable: false},
        {text: 'Amount', value: 'date_of_deposit', sortable: false},
        {text: 'OR #', value: 'date_of_deposit', sortable: false},
        {text: 'Details', value: 'date_of_deposit', sortable: false},
        {text: 'Receipt', value: 'date_of_deposit', sortable: false},
        {text: 'Delete', value: 'date_of_deposit', sortable: false},
      ],
      data_items: [],
      month_of_items: [],
      branch_items: [],
      liquidated_items: [],
      selected_fund: {},
      category_items: [],
      year_of_items: [],
      tie_up_items: [],
      type_of_service: '',
      month_of_id: '',
      branch_id: '',
      tie_up_id: '',
      category_id: '',
      selection: '',
      year_of: '',
      type_of_fund: '',
      rendered_to: '',
      total_amount: '0.00',
      total_amount1: '0.00',
      is_authorize_all: true,
    }
  }
  export default {
    components: {
      snackBarDialog,
      ViewUploadCheque,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiPrinter,
          mdiEyeCircle,
          mdiDelete,
          mdiFileFind,
          mdiClose, mdiSwapHorizontal
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {

      this.is_authorize_all = true
      if (this.position != 'ADMIN' && this.position != 'ACCOUNTING' && this.position != 'FINANCE' && this.position != 'CMD') {
        this.is_authorize_all = false
      }
      this.initialize_data()
    },
    props: {
      title: String,
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['employee_id', 'month_of', 'address', 'contact', 'name', 'department', 'position', 'employee_category_id', 'employee_branch_id', 'employee_branch_data', 'name_credit']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing', 'accounting', 'admin', 'amd', 'audit', 'book', 'creative', 'ecut', 'hr', 'it', 'prot', 'trea']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months', ['list_of_transaction_month']),
      ...mapActions('monthly_liquidation', ['list_of_department_fund', 'delete_regular_liquidation', 'initialize_search_voucher', 'get_uploaded_receipt']),
      ...mapActions('branch_information', ['branch_data']),
      async initialize_data() {
        await this.initialize_search_voucher()
          .then(response => {
            this.year_of_items = response.data[0].year_and_month
            this.category_items = response.data[0].category
            this.category_items.splice(0, 1)
          })
          .catch(error => {
            console.log(error)
          })
        if (this.is_authorize_all) {
          this.year_of_items.splice(0, 0, {
            id: -1,
            year: 'All',
          })
          await this.selected_year('All')
        } else {
          this.year_of = this.year_of_items[0].year
          await this.selected_year(this.year_of_items[0].year)
          this.category_id = this.employee_category_id
        }
        this.title === 'SALES DRIVE MONITORING' ? this.type_of_fund = 'Sales Drive' : this.type_of_fund = 'Revolving Fund'
        await this.selected_category()
      },
      selected_year(value) {
        if (value != 'All') {
          var index = this.year_of_items.map(function (x) {
            return x.year;
          }).indexOf(value)
          if (index != -1) {
            this.month_of_items = this.year_of_items[index].months_data
          }
        }
        this.month_of_items.splice(0, 0, {
          id: -1,
          month_of: 'All',
        })
        this.month_of_id = -1
        this.selected_month()
      },
      async selected_category() {
        if (this.position === 'EMPLOYEE') {
          if (this.employee_branch_data.is_region === 0 && this.employee_branch_data.is_territory === 0) {
            this.selection = 'Branch'
          } else if (this.employee_branch_data.is_region === 1) {
            this.selection = 'Regional'
          } else if (this.employee_branch_data.is_territory === 1) {
            this.selection = 'Territory'
          }
        }
        this.is_forbidden = true
        if (this.category_id === 4 || this.category_id === 3) {
          this.is_forbidden = false
        } else {
          this.selection = ''
        }
        this.branch_id = -1
        await this.branch_data({
          category_id: this.category_id,
          is_region: this.selection === 'Regional' ? 1 : 0,
          is_territory: this.selection === 'Territory' ? 1 : 0,
        })
          .then(response => {
            this.branch_items = response.data[0].active
          })
          .catch(error => {
            console.log(error)
          })
        if (this.is_authorize_all) {
          this.branch_items.splice(0, 0, {
            id: -1,
            branch_code: 'All'
          })
        } else {
          this.branch_id = this.employee_branch_id
        }
        await this.selected_month()
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        this.saving = true
        this.alert = false
        this.data_items = []
        this.total_amount = '0.00'
        this.total_amount1 = '0.00'
        if (this.$refs.form2.validate()) {
          var proceed = true;
          // if (this.position === 'ACCOUNTING' && (this.category_id === 2 || this.category_id === 1)) {
          //   proceed = false
          // }
          if (proceed) {
            this.list_of_department_fund({
              month_of: this.month_of_items[this.month_of_items.map(function (x) {
                return x.id;
              }).indexOf(this.month_of_id)].month_of,
              department: this.branch_items[this.branch_items.map(function (x) {
                return x.id;
              }).indexOf(this.branch_id)].branch_code,
              branch_id: this.branch_id,
              name: this.name_credit,
              is_admin: this.is_authorize_all ? 1 : 0,
              year_of: this.year_of,
              category_id: this.category_id,
              is_region: this.selection === 'Regional' ? 1 : 0,
              is_territory: this.selection === 'Territory' ? 1 : 0,
              is_sales_drive: this.title === 'SALES DRIVE MONITORING' || this.type_of_fund === 'Sales Drive' ? 1 : 0,
            })
              .then(response => {
                this.saving = false
                if (response.status === 200) {
                  this.data_items = response.data
                  var tot = 0
                  var tot1 = 0
                  this.data_items.forEach(function (item) {
                    tot += parseFloat(item.balance)
                    tot1 += parseFloat(item.amount)
                  });
                  this.total_amount = this.formatPrice(tot)
                  this.total_amount1 = this.formatPrice(tot1)
                }
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            this.saving = false
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      view_liquidations(value) {
        this.is_view_liquidation = true
        this.key++
        this.selected_fund = value
        this.liquidated_items = value.liquidations
      },
      delete_data(value) {
        this.is_deleting = true
        this.delete_regular_liquidation({
          id: value
        })
          .then(() => {
            this.liquidated_items.splice(this.liquidated_items.map(function (x) {
              return x.id;
            }).indexOf(value))
            this.is_deleting = false
          })
          .catch(error => {
            console.log(error)
          })
      },
      close_dialog() {
        this.is_view_liquidation = false
        this.selected_month()
      },
      async view_receipt(value) {
        this.can_view_uploaded_cheque = true
        this.key2++
        this.uploaded_data_items = []
        await this.get_uploaded_receipt({
          id: value,
        })
          .then(response => {
            this.uploaded_data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async print_data() {
        this.printt = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing


        widths = [55, 35, 45, 90, 90, 70, 60, 40]
        payments_array.push([
          {text: 'Month Of', alignment: 'left', style: 'main_info'},
          {text: 'Type', alignment: 'left', style: 'main_info'},
          {text: 'Maf #', alignment: 'left', style: 'main_info'},
          {text: 'Name of Cadaver', alignment: 'left', style: 'main_info'},
          {text: 'Name of Plan Holder', alignment: 'left', style: 'main_info'},
          {text: 'Branch', alignment: 'left', style: 'main_info'},
          {text: 'Service By', alignment: 'left', style: 'main_info'},
          {text: 'Balance', alignment: 'left', style: 'main_info'},
        ])


        var tott = this.total_amount
        this.data_items.forEach(function (item, index, payment) {
          payments_array.push([
            {
              text: item.transaction_month.month_of,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.type_of_service,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.maf_no,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.name_of_cadaver,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.name_of_member,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.branch.branch_code,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.tie_up != null ? item.tie_up.tie_up_name : '',
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: (item.balance / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2'
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2'
              },
              {
                text: tott,
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[this.month_of_items.map(function (x) {
                      return x.id;
                    }).indexOf(this.month_of_id)].month_of,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'BRANCH: ',
                  {
                    text: this.branch_items[this.branch_items.map(function (x) {
                      return x.id;
                    }).indexOf(this.branch_id)].branch_code,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'TYPE OF SERVICE: ',
                  {
                    text: this.type_of_service,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'RENDERED TO: ',
                  {
                    text: this.rendered_to,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image: this.position === 'ADMIN' ? this.admin :
                    (this.position === 'TREASURY' ? this.trea :
                      (this.position === 'ACCOUNTING' ? this.accounting :
                        (this.position === 'AMD' ? this.amd :
                          (this.position === 'PROPERTY CUSTODIAN' ? this.prot :
                            (this.position === 'HUMAN RESOURCE' ? this.hr :
                              (this.position === 'BOOKKEEPING' ? this.book :
                                (this.position === 'CMD' ? this.accounting : imgData))))))),
                  width: 60,
                  height: 54,
                  style: 'logo'
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'LIST OF SERVICE',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {

              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left'
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printing_, width: 34, height: 20, style: 'logo'},
                {
                  text: ' '
                },
              ],
            };
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printt = false
      },
      async print_data2() {
        this.printt = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printing_ = this.printing


        widths = [35, 55,55, 55, 125, 35, 70, 60]
        payments_array.push([
          {text: 'Voucher #', alignment: 'left', style: 'main_info'},
          {text: 'Branch', alignment: 'left', style: 'main_info'},
          {text: 'Credited To', alignment: 'left', style: 'main_info'},
          {text: 'Particulars', alignment: 'left', style: 'main_info'},
          {text: 'Description', alignment: 'left', style: 'main_info'},
          {text: 'Amount', alignment: 'left', style: 'main_info'},
          {text: 'OR #', alignment: 'left', style: 'main_info'},
          {text: 'OR Details', alignment: 'left', style: 'main_info'},
        ])


        var tott = 0
        this.liquidated_items.forEach(function (item, index, payment) {
          tott += parseFloat(item.amount)
          payments_array.push([
            {
              text: item.cash_voucher.voucher_no,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.branch.branch_code,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.credit_to,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.particulars.particulars,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.description,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: (item.amount / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.or_no,
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: item.specify,
              alignment: 'left',
              style: 'tableExample2'
            },

          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: ''
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2'
              },
              {
                text: (tott / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: ''
              },
              {
                text: ''
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'DETAILS: ',
                  {
                    text: this.selected_fund.description,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'CREDITED TO: ',
                  {
                    text: this.selected_fund.credit_to,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'AMOUNT: ',
                  {
                    text: this.formatPrice(this.selected_fund.amount),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image: this.position === 'ADMIN' ? this.admin :
                    (this.position === 'TREASURY' ? this.trea :
                      (this.position === 'ACCOUNTING' ? this.accounting :
                        (this.position === 'AMD' ? this.amd :
                          (this.position === 'PROPERTY CUSTODIAN' ? this.prot :
                            (this.position === 'HUMAN RESOURCE' ? this.hr :
                              (this.position === 'BOOKKEEPING' ? this.book :
                                (this.position === 'CMD' ? this.accounting : imgData))))))),
                  width: 60,
                  height: 54,
                  style: 'logo'
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'REVOLVING FUND LIQUIDATIONS',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {

              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left'
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printing_, width: 34, height: 20, style: 'logo'},
                {
                  text: ' '
                },
              ],
            };
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printt = false
      }
    },
  }
</script>
